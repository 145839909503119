@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
 @import url('https://fonts.cdnfonts.com/css/optima');
 @import url('https://fonts.cdnfonts.com/css/br-firma');

body {
  margin: 0;
  padding: 0%;
  font-family: 'outfit', sans-serif;
background-color: #f5f5f5;
}

/* *::-webkit-scrollbar {
  width: 0px !important;
} */

/* Hide scrollbar for Firefox */
/* * {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

} */

/* Hide scrollbar for Edge and IE */
/* * {
  -ms-overflow-style: none;
} */

.show_scrollbar::-webkit-scrollbar {

  width: 5px !important;
  margin: 5px !important;
}

.show_scrollbar::-webkit-scrollbar-thumb {
  background-color: #ccc;
  width: 5px;
  border-radius: 5px;
  margin: 5px !important;
  /* You can customize the color of the thumb */
  /* border-radius: 6px; */
  /* You can customize the border radius */
}

.hide_scrollbar::-webkit-scrollbar {
  display: none;

}

.hide_scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.three-line-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.four-line-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.one-line-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.two-line-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.carousel {
  position: relative;
  width: 100%;
  height: 400px;
}

.carousel-images {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.carousel-image {
  display: none;
  /* background-size: contain; */
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  /* background-repeat: repeat-x; */
}

.carousel-image.active {
  display: block;
  animation: fade 1s ease-in-out;
}

.carousel-dots {
  display: flex;
    flex-direction: row;
    gap: 2px;
    justify-content: center;
    align-items: center;
    margin-top: -20px;
}

.carousel-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #fae8ea;
  margin: 0 5px;
  cursor: pointer;
  transition: 0.5s all linear;
}

.carousel-dot.active {
  height: 8px;
  border-radius: 100px;
  background-color: #bb162f;
}

.carousel-2 {
  position: relative;
  width: 100%;

}

.carousel-images-2 {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

}

.carousel-image-2 {
  display: none;
  /* background-size: contain; */
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  /* background-repeat: repeat-x; */
}

.carousel-image-2.active {
  display: block;
  animation: fade 1s ease-in-out;
}

.carousel-dots-2 {
  /* position: absolute; */
  /* top: 100px;
  left: 95%; */
  /* transform: translateX(-50%); */
  display: flex;
  flex-direction: row;
  gap: 2px;
  justify-content: center;
  align-items: center;
}

.carousel-dot-2 {
  width:6px;
  height: 6px;
  border-radius: 50%;
  background-color: #d7d7d7;
  margin: 0 3px;
  cursor: pointer;
  transition: 0.5s all linear;
}

.carousel-dot-2.active {

  border-radius: 100px;
  background-color: #fff;
}

.carousel-caption {
  position: absolute;
  bottom: 30px;
  left: 20%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  background-color: #2e1834;
  padding: 25px;
  color: #fff;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  50%{
opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}


.paystack-button {
  background-color: #bc172f;
  border: none;
  padding: 20px;
  font-family: outfit;
  font-weight: bold;
  width: 100%;
  color: #fff;
  margin-top: 20px;
    border-radius: 12px;
    cursor: pointer;
}

.paystack-button-disabled {
  background-color: #d7d7d7;
  cursor: not-allowed;
  padding: 20px;
  font-family: outfit;
  font-weight: bold;
  width: 100%;
  border: none;
  color: #9f9f9f;
  margin-top: 20px;
  border-radius: 12px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-block-content img {
  width: 350px;
  border-radius: 8px;
  align-items: center;
  margin-bottom: 20px;
height: 250px;
}

.ql-editor {
  font-family: 'outfit' !important;
  padding: 0 10px !important;

  margin-top: 0px !important;
  font-weight: 300 !important;
}

.MuiModal-root:focus {
  outline: none;
}

.MuiModal-root .MuiBox-root:focus {
  outline: none;
}